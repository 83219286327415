// * BUTTONS

/// Restore styles the mat-v3 for mat-icon-button
.mat-mdc-icon-button {
  width: 40px !important;
  height: 40px !important;
  letter-spacing: normal !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

// * FORMS

/// Material form field prefix and suffix styling to center the element.
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  place-self: center;

  div,
  span,
  button {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// * INPUTS
/// Will prevent show the border in the middle of outlined inputs @material v15.
.mat-mdc-form-field {
  /// Remove line horizontal in inputs outline
  .mdc-notched-outline__notch {
    border-right: none;
  }

  .mdc-text-field--outlined {
    &.mdc-text-field--focused {
      .mdc-notched-outline__notch {
        border-right: none;
      }
    }
  }
}

/// Style general for input apply style slim with or not suffix icon
.input-slim-mdc {
  .mat-mdc-form-field {
    height: 45px !important;
    width: 100% !important;
    flex-direction: inherit !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    margin-top: -10px !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px !important;
    font-size: 1rem !important;
  }
}

// * SNACKBAR

/// Overrides the default accent color for the snackbar action
.snackbar {
  .mdc-snackbar__surface {
    min-width: 100px !important;
  }

  .mat-mdc-snack-bar-action {
    color: #fff;
  }

  .mat-mdc-button {
    min-width: 35px !important;

    .mdc-button__label {
      color: #fff;
    }
  }
}

.snackbar-error {
  .mat-mdc-snack-bar-handset,
  .mat-mdc-snack-bar-container,
  .mat-mdc-snack-bar-label {
    color: #b91c1c !important;
    background-color: #fee2e2 !important;
    /* stylelint-disable-next-line */
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;

    @apply rounded;
  }

  .mdc-snackbar__surface {
    min-width: 416px !important;

    @apply p-0;
  }

  .mat-mdc-button {
    min-width: 35px !important;

    .mdc-button__label {
      @apply text-red-500;
    }
  }
}

// * MENU

// Size font default
.mat-mdc-menu-item.mdc-list-item {
  span {
    font-size: 1rem !important;
  }
}

.mat-mdc-menu-item-text {
  font-size: 1rem !important;

  p {
    font-size: 1rem !important;
  }
}

// * SELECT

/// Set size font for options the select
.mat-mdc-option.mdc-list-item {
  background-color: #fff;
  font-size: 1rem !important;
}

/// Set font styles the sections headers in select
.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-size: 1rem !important;

  /* stylelint-disable-next-line */
  color: map-get($secondary-gray-palette, 400) !important;
}

// Fix style datepicker
.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}

.mat-calendar-body-selected {
  /* stylelint-disable-next-line */
  background-color: map-get($accent-palette, 500);
  color: #fff;
}

// Style of chips general to apply style with sky color.
.chip-filters-update::ng-deep {
  .mat-mdc-standard-chip.mdc-evolution-chip--selectable {
    @apply bg-white border-[1px] border-sky-500;
  }

  .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
    flex-flow: row !important;
  }

  /* stylelint-disable */
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
    .mdc-evolution-chip__graphic {
    display: none;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    margin-left: 10px;
    color: rgb(14 165 233) !important;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
      .mdc-evolution-chip--disabled
    )
    .mdc-evolution-chip__text-label {
    color: white !important;
  }

  /* stylelint-enable */

  .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected {
    @apply bg-sky-500 text-white;

    i {
      @apply text-white;
    }
  }
}

/* stylelint-disable */
.check-sky ::ng-deep {
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
    ~ .mdc-checkbox__background {
    @apply bg-sky-500 border-sky-500;
  }
}
/* stylelint-enable */

:host {
  .tabs-rithm {
    &::ng-deep {
      .mat-mdc-tab-header {
        @apply bg-gray-700;
      }

      .mat-mdc-tab {
        border-right: 1px solid;

        @apply border-gray-600;
      }
    }

    & ::ng-deep {
      &.mat-mdc-tab-header-pagination-chevron {
        width: 16px;
        height: 16px;
        border-color: #9ca3af !important;
      }

      &.mat-mdc-tab-header-pagination-after {
        border-left: 1px solid #4b5563;
      }

      &.mat-mdc-tab-header-pagination-before {
        border-right: 1px solid #4b5563;
      }

      &.mat-mdc-tab-header-pagination {
        min-width: 35px;
        width: 40px !important;
      }

      .mdc-tab {
        .mdc-tab-indicator__content--underline {
          @apply hidden;
        }

        .label-tab {
          @apply text-gray-300;
        }

        &:hover {
          .mdc-tab-indicator {
            @apply bg-gray-600;
          }

          .icon-tab {
            @apply text-white;
          }

          .label-tab {
            @apply text-white;
          }
        }

        &.mdc-tab--active {
          .mdc-tab-indicator {
            @apply bg-white;

            span {
              @apply hidden;
            }
          }

          .icon-tab {
            @apply text-sky-500;
          }

          .label-tab {
            @apply text-sky-500;
          }
        }
      }
    }
  }
}
